import { gql } from '@apollo/client'

export const CREATE_COLLABORATOR = gql`
  mutation CreateCollaborator(
    $birthdate: String!
    $familyName: String!
    $genderID: Int!
    $mobilePhone: String!
    $middleName: String!
    $name: String!
    $permissions: [String!]!
  ) {
    createCollaborator(
      name: $name
      middleName: $middleName
      familyName: $familyName
      birthdate: $birthdate
      genderID: $genderID
      mobilePhone: $mobilePhone
      permissions: $permissions
    ) {
      message
      code
      status
    }
  }
`
