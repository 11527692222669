// Packages
import { useForm } from 'antd/lib/form/Form'
import { navigate } from 'gatsby'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, ApolloError } from '@apollo/client'
import { toast } from 'react-toastify'
import { LeftOutlined } from '@ant-design/icons'

// Components
import FormOperator from '../../components/FormOperator'
import HeaderModule from '../../components/HeaderModule'
import CustomButton from '../../components/Storybook/customButton'
import Loading from '../../components/Shared/Loading'
import ModalResponse from '../../components/Shared/ModalResponse'
import ModalResult from '../../components/Shared/ModalResult'
import { StyledButtonPrimary } from '../../components/Shared/Button/styledComponent'
import Permissions from '../../components/Permissions'

// Hooks
import useErrorHandler from '../../hooks/useErrorHandler'

// Styled Components
import { StyledBackButton } from '../OperatorsContainer/styledComponents'

// Queries
import { GET_PERMISSIONS_LIST } from '../../graphql/queries/operators'
import { CREATE_COLLABORATOR } from '../../graphql/mutation/collaborators'

// Types
import { WorkerInterface } from '../../types/WorkerType'

// Utils
import { setGA } from '../../utils/workers'

// Lodash
import { mapKeys } from 'lodash'

type propsResult = {
  isModalVisible: boolean
  content: any
}

const resultInitialState: propsResult = {
  isModalVisible: false,
  content: <></>
}

const OperatorCreateContainer: React.FC = () => {
  const [initialValues] = useState<any>({
    APP_PEM_CASH_REGISTER_00: true,
    APP_PEM_MODELORAMA_CLUB_00: true,
    APP_PEM_INVENTORY_03: true,
    APP_PEM_REPORTS_SALES_02: true
  })
  const [result, setResult] = useState(resultInitialState)
  const [OperatorForm] = useForm()
  const [step, setStep] = useState(1)
  const [disabledForm, setDisabledForm] = useState(true)
  const [apolloError, setApolloError] = useState<ApolloError>()
  const { data: permissionsData } =
    useQuery<PermissionsTypes.Permissions>(GET_PERMISSIONS_LIST)
  const [
    createOperator,
    { data: createOperatorData, loading: createOperatorLoading }
  ] = useMutation(CREATE_COLLABORATOR)
  const errorMessage = useErrorHandler(apolloError, createOperatorLoading)

  const handleFinishCreateOperator = () => {
    navigate('/operators')
    setResult(resultInitialState)
  }

  useEffect(() => {
    if (!createOperatorLoading && createOperatorData) {
      setResult({
        isModalVisible: true,
        content: (
          <ModalResult
            key={`success-${Math.random()}`}
            status={'success'}
            subTitle={
              <>
                <p style={{ color: 'black', textAlign: 'center' }}>
                  <strong>Tu Operador se creo con éxito.</strong>
                </p>
                <div style={{ color: 'black', textAlign: 'justify' }}>
                  <p>
                    Pídele a tu operador que siga los siguientes pasos pasos
                    para verificar su cuenta y así poder hacer uso de la POS.
                  </p>
                  <ol style={{ paddingInlineStart: '20px' }}>
                    <li>
                      Tu Operador tendrá que ingresar a
                      <strong> mimodelorama.modelorama.com.mx</strong> desde su
                      celular
                    </li>
                    <li>
                      Tu Operador tendrá que Iniciar sesión ingresando el número
                      de su celular
                    </li>
                    <li>
                      Tu Operador tendrá que ingresar el código que le llegará a
                      su celular
                    </li>
                    <li>Tu Operador creará una contraseña nueva</li>
                    <li>
                      Tu Operador tendrá que Iniciar sesión nuevamente con la
                      contraseña que recién creo
                    </li>
                    <li>
                      Tu operador ya puede iniciar sesión en tú POS con su
                      número celular y la contraseña que acaba de crear
                    </li>
                  </ol>
                </div>
              </>
            }
            extra={
              <StyledButtonPrimary
                key="button-finish-create-operator"
                type="button"
                onClick={handleFinishCreateOperator}
              >
                Aceptar
              </StyledButtonPrimary>
            }
          />
        )
      })
    }
  }, [createOperatorData, createOperatorLoading])

  useEffect(() => {
    if (errorMessage && apolloError) {
      toast.error(errorMessage)
      setStep(2)
      setApolloError(undefined)
    }
  }, [errorMessage, apolloError])

  const getArrayPermissions = (data: any) => {
    const permissions: Array<string> = []
    mapKeys(data, (value, key) => {
      if (key.includes('APP_PEM') && value === true) {
        permissions.push(key)
      }
    })
    return permissions
  }

  const onFinish = (data: any) => {
    const operatorData: WorkerInterface = {
      name: data.name,
      middleName: data.middleName,
      familyName: data.familyName,
      genderID: 1,
      mobilePhone: data.mobilePhone,
      birthdate: moment(data.birthdate).format('YYYY-MM-DD'),
      permissions: getArrayPermissions(data)
    }
    if (step > 2) {
      setGA(data, 'create')
      createOperator({
        variables: operatorData
      }).catch((error) => {
        setApolloError(error)
      })
    }
  }

  const onFieldsChange = () => {
    const fields = [
      'name',
      'middleName',
      'familyName',
      'mobilePhone',
      'birthdate'
    ]
    const minAge = moment().subtract(18, 'y')
    setDisabledForm(
      !!OperatorForm.getFieldsError().filter(({ errors }) => errors.length)
        .length
    )

    // The user fill the first step
    fields.forEach((element) => {
      if (!OperatorForm.getFieldValue(element)) {
        setDisabledForm(true)
      }
    })

    // The user is an adult
    if (minAge < OperatorForm.getFieldValue('birthdate')) {
      setDisabledForm(true)
      OperatorForm.setFields([
        {
          name: 'birthdate',
          errors: ['El operador debe ser mayor de edad']
        }
      ])
    }
  }

  const backButton = (
    <StyledBackButton>
      <CustomButton
        id="button-icon-back-sales-report"
        name="buttonIconBackSalesReport"
        className="button-icon-back-operators"
        dataTestId="data-test-button-icon-back-sales-report"
        icon={<LeftOutlined />}
        onClick={() => {
          step === 1 ? navigate('/operators') : setStep(1)
        }}
      />
    </StyledBackButton>
  )

  if (createOperatorLoading) return <Loading />

  return (
    <>
      <HeaderModule title="Crear operador" backButton={backButton} />
      <FormOperator
        step={step}
        permissions={
          <Permissions
            data={permissionsData ? permissionsData.getPermissions : []}
            initialValues={initialValues}
          />
        }
        form={OperatorForm}
        onFieldsChange={onFieldsChange}
        onFinish={onFinish}
        disabled={disabledForm}
        nextStep={() => setStep(step + 1)}
        initialValues={initialValues}
      />
      <ModalResponse
        isModalVisible={result.isModalVisible}
        content={result.content}
        onCancel={handleFinishCreateOperator}
      />
    </>
  )
}

export default OperatorCreateContainer
